import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [2,3,7,4,5,6];

export const dictionary = {
		"/(root)": [~8,[2]],
		"/(root)/admin": [~11,[2,3]],
		"/(root)/admin/exercises": [~12,[2,3]],
		"/(root)/admin/prompt/[promptId]": [~13,[2,3]],
		"/(root)/admin/questionnaires": [~14,[2,3]],
		"/(root)/admin/retention": [~15,[2,3]],
		"/(root)/admin/retention/[tutorId]": [~16,[2,3]],
		"/(root)/admin/speaking": [~17,[2,3]],
		"/(root)/admin/speaking/[exerciseId]": [~18,[2,3]],
		"/(root)/admin/users": [~19,[2,3]],
		"/(root)/admin/users/[tutorId]": [~20,[2,3]],
		"/(root)/admin/users/[tutorId]/[studentId]/task-completeness": [~21,[2,3]],
		"/api/v1/callback/google": [~40],
		"/auth": [~41,[7]],
		"/auth/intermidiate": [~45,[7]],
		"/auth/logout": [~46,[7]],
		"/auth/[type]/google": [~42,[7]],
		"/auth/[type]/login": [~43,[7]],
		"/auth/[type]/register": [~44,[7]],
		"/(root)/demo/listening": [~22,[2]],
		"/(root)/demo/speaking/progress": [23,[2]],
		"/(root)/demo/speaking/words": [~24,[2]],
		"/(root)/s": [~25,[2,4]],
		"/(root)/s/language": [~27],
		"/(root)/s/speaking": [~28,[2,4]],
		"/(root)/s/speaking/training": [~30,[2,4]],
		"/(root)/s/speaking/[exerciseId]": [~29,[2,4]],
		"/(root)/s/vocabulary": [~31,[2,4]],
		"/(root)/s/[homeworkId]/[exerciseId]": [~26],
		"/(root)/t": [~32,[2,5]],
		"/(root)/t/workspace": [~33,[2,5,6]],
		"/(root)/t/workspace/(tools)/grammar": [~34],
		"/(root)/t/workspace/(tools)/listening": [~35],
		"/(root)/t/workspace/(tools)/reading": [~36],
		"/(root)/t/workspace/(tools)/speaking": [~37],
		"/(root)/t/workspace/student/[userId]": [~39,[2,5,6]],
		"/(root)/t/workspace/(tools)/vocabulary": [~38],
		"/(root)/[homeworkId]/[exerciseId]": [~9,[2]],
		"/(root)/[homeworkId]/[exerciseId]/stats": [~10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';